<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget
      .widget-header
        h1.title External Offers
        i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'externalOffersList'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .form-group
              label Search
              input.form-control(type="text", placeholder="Keyword...", v-model="filters.keyword")
            .form-group.fg-advertiser
              label Advertisers ({{ visibleAdvertiserOptions.length }})
                b-checkbox(switch="", v-model="advertiserOptionsArchive")
              multiselect(:multiple="true", :options="visibleAdvertiserOptions", v-model="filters.advertiser_id",
                track-by="v", label="t", deselect-label="", select-label="")
            //.form-group
              label Scan status
              select.form-control(v-model="filters.scan_status")
                option(v-for="o in scanStatusOptions", :value="o.v") {{ o.t }}
            //.form-group
              label Max redirects
              input.form-control(type="text", placeholder="Max Redirect", v-model.number="filters.max_redirects")
            .form-group
              label Country
              input.form-control(type="text", placeholder="Country", v-model="filters.country")
            .form-group
              label Platform
              select.form-control(v-model="filters.platform", @change="loadData(true)")
                option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
            .form-group.no-label
              b-form-checkbox(v-model="filters.is_active") Active Only
            //.form-group.no-label
              b-form-checkbox(v-model="filters.has_offer") Has offer
            .form-group.no-label
              b-form-checkbox(v-model="filters.is_approved") Approved Only

          .form-row-end.desktop
            .form-group
              label &nbsp;
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download
                    | Export

          .w-100.mobile.p-2.border-top.position-sticky.bg-white(style="bottom: 0; z-index: 2;")
            b-button-group.submit-filters-button
              b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                b-dropdown-item(@click="loadData(1)")
                  i.la.la-download.mr-1
                  | Export

        p.clearfix &nbsp;

        .ov-table-wrapper
          table.table.table-bordered
            thead
              tr
                th ID
                th(v-b-tooltip.hover, title="Approved?") Apr.
                th Status
                th Advertiser
                th &nbsp;
                th Name
                th(v-b-tooltip.hover, title="ID in advertiser's platform") Adv.ID
                th Offer
                th Payout
                th Cap
                th Type
                th Country
                th Platform
                th Preview
                th Scan
                th Last update
                th Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="17") No matching records were found
              tr(v-for="r in records", v-bind:key="r.id")
                td {{ r.id }}
                td.col-approved
                  i.la.la-check(v-if="r.approved")
                td
                  span.badge.badge-secondary(v-if="r.status==='inactive'") Inactive
                  span.badge.badge-success(v-if="r.status==='active'") Active
                td
                  entity(:id="r.advertiser_id", :name="r.advertiser_name", type="advertiser")
                td
                  div.app-icon-container
                    img(v-if="r.icon", :src="r.icon")
                td
                  div.package-id {{ r.package_id }}
                    span(v-if="r.app_id")  / {{ r.app_id }}
                  div {{ r.name }}
                td.col-ext-id
                  span(v-if="r.external_id") {{ r.external_id }}
                  span(v-if="r.external_campaign_id") [{{ r.external_campaign_id }}]
                td
                  div(v-for="oid in r.offer_ids")
                    router-link(:to="{name:'offer-edit', params: {id:oid}}") {{ oid }}
                  router-link(:to="{name:'offer-add', query:{external_offer_id: r.id}}")
                    i.la.la-plus
                td {{ r.payout }} {{ r.currency }}
                td {{ r.daily_conversion_cap }}
                  span(v-if="r.remaining_conversion_cap")  / {{ r.remaining_conversion_cap }}
                td {{ r.payout_type }}
                td.edit-inline
                  div.country-cell
                    div.countries(:class="{'text-muted':r.override_country}", v-if="r.country") {{ r.country.join(', ') }}
                    quick-edit(field="override_country", :r="r", @on-save="updateCountry(r)", :wrapped="true")
                td.edit-inline
                  div(:class="{'text-muted':r.override_platform}") {{ r.platform }}
                  quick-edit(field="override_platform", :r="r", @on-save="updatePlatform(r)", :wrapped="true")
                td
                  a(:href="r.preview_url", target="_blank", v-b-tooltip.hover, title="Store Preview")
                    i.la.la-search
                td
                  span.scan-status(v-if="r.offerScan")
                    span(v-if="r.offerScan.status===0") New
                    span.scan-success(v-if="r.offerScan.status===1") Success
                    span.scan-failure(v-if="r.offerScan.status===2") Failure
                    span.scan-error(v-if="r.offerScan.status===3") API Error
                  div.scan-info
                    span(v-if="r.offerScan") {{ r.offerScan.redirects }} hops
                    a.btn.btn-scan(href="javascript:void(0)", @click="showOfferScanModal(r)", v-b-tooltip.hover.bottom, title="Scan Dialog")
                      i.la.la-search
                td {{ r.updated_at }}
                td.actions
                  button.btn.btn-secondary(type="button", @click="showOriginal(r)", v-b-tooltip.hover.bottom, title="Show Original")
                    i.la.la-code
                  //router-link.btn.btn-sm.btn-secondary(:to="{name:'advertiser-edit', params: {id:r.id}}")
                    i.la.la-pencil
                  //| &nbsp;
                  //a.btn.btn-secondary(href="")
                    i.la.la-trash
          paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<style lang="scss">
.ov-table-wrapper {
  table.table.table-bordered {
    thead {
      tr {
        th {
          padding: 3px;
        }
      }
    }

    .scan-status {
      .scan-success {
        background: #00aaaa;
        color: #fff;
      }
      .scan-failure {
        background: #aa0000;
        color: #fff;
      }
      .scan-error {
        background: #cc7700;
        color: #fff;
      }
      span {
        display: inline-block;
        padding: 0 3px;
        line-height: 18px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
      }
    }
    .scan-info {
      width: 68px;
      display: flex;
      span {
        flex: 1;
      }
      a {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background: #eee;
        border-radius: 100%;
        padding: 0;
        &:hover {
          background: #ccc;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 3px;

          &.col-approved {
            text-align: center;
            font-size: 18px;
          }

          &.col-ext-id {
            span {
              font-size: 11px;
              display: block;
            }
          }

          .app-icon-container {
            width: 40px;
            height: 40px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
            background: #ccc;
            border-radius: 10px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import Vue from 'vue';
import OfferScanModal from '../modals/OfferScanModal';
import CodeViewModal from '../modals/CodeViewModal';

export default {
  name: 'ExternalOfferList',
  async beforeRouteEnter(to, from, next) {
    let advertiserId = +to.query.advertiser_id;

    let advertiserOptions = await Vue.ovData.advertiser.getOptions();
    next((vm) => {
      vm.advertiserOptions = advertiserOptions;
      if (advertiserId) {
        vm.filters.advertiser_id = advertiserId;
      }
      vm.loadData();
      return vm;
    });
  },
  components: {
    OfferScanModal,
    CodeViewModal,
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    visibleAdvertiserOptions() {
      return this.advertiserOptions.filter((a) => {
        return this.advertiserOptionsArchive || a.status;
      });
    },
  },
  data() {
    const defaultFilters = {
      advertiser_id: [],
      keyword: '',
      scan_status: 'all',
      is_active: true,
      max_redirects: 0,
      country: '',
      platform: 'all',
      is_approved: true,
      // has_offer: false
    };
    return {
      areFiltersOpened: false,
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      defaultFilters,
      filters: { ...defaultFilters },
      records: [],
      scanStatusOptions: [
        { v: 'all', t: 'All' },
        { v: 'scan', t: 'All scanned' },
        { v: 'success', t: 'Success only' },
        { v: 'failure', t: 'Failure only' },
        { v: 'error', t: 'Error only' },
      ],
      platformOptions: [
        { v: 'all', t: 'All' },
        { v: 'android', t: 'Android' },
        { v: 'ios', t: 'iOS' },
      ],
      advertiserOptionsArchive: false,
      advertiserOptions: [],
      sortBy: {
        field: 'id',
        direction: 'desc',
        sort: null,
      },
    };
  },
  methods: {
    showOfferScanModal(externalOffer) {
      try {
        this.busy = true;
        // let resp = await this.$ovReq.get('advertiser-feed/get/' + r.id + '?extend=1');
        // let advertiserFeed = resp.entity;
        this.busy = false;
        this.$modal.show(
          OfferScanModal,
          {
            externalOffer,
          },
          {
            height: '90%',
          },
        );
      } catch (e) {
        this.$ovNotify.error(e);
        this.busy = false;
      }
    },

    showOriginal(r) {
      try {
        this.busy = true;
        this.busy = false;
        this.$modal.show(
          CodeViewModal,
          {
            code: r.original,
          },
          {
            height: '90%',
          },
        );
      } catch (e) {
        this.$ovNotify.error(e);
        this.busy = false;
      }
    },

    async updateCountry(r) {
      const reqParams = {
        id: r.id,
        country: r._override_country,
      };
      try {
        let resp = await this.$ovReq.post('externalOffer/updateCountry', reqParams);
        r.override_country = resp.country;
        r._override_country = resp.country;
        r._edit_override_country = false;
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        console.error(e);
      }
    },
    async updatePlatform(r) {
      const reqParams = {
        id: r.id,
        platform: r._override_platform,
      };
      try {
        let resp = await this.$ovReq.post('externalOffer/updatePlatform', reqParams);
        r.override_platform = resp.platform;
        r._override_platform = resp.platform;
        r._edit_override_platform = false;
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        console.error(e);
      }
    },
    async loadData(doExport) {
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        keyword: this.filters.keyword,
        max_redirects: this.filters.max_redirects,
        scan_status: this.filters.scan_status,
        country: this.filters.country,
        platform: this.filters.platform,
        is_active: this.filters.is_active ? 1 : 0,
        is_approved: this.filters.is_approved ? 1 : 0,
        // has_offer: this.filters.has_offer ? 1 : 0,
        sort_col: this.sortBy.field,
        sort_dir: this.sortBy.direction,
      };
      try {
        let resp = await this.$ovReq.get('externalOffer/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.records.forEach((r) => {
            Vue.set(r, '_edit_override_country', false);
            Vue.set(r, '_override_country', r.override_country);

            Vue.set(r, '_edit_override_platform', false);
            Vue.set(r, '_override_platform', r.override_platform);

            // Vue.set(r, '_status', r.status);
            // Vue.set(r, '_fetch_api', r.fetch_api);
          });
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    // this.loadData();
  },
};
</script>
